@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.paginatedCardsContainer {
  overflow-anchor: none;
  overflow-x: hidden;
  padding-top: 1px;
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
}
.paginatedCardsContainer .card-listing__mobile {
  padding: 16px !important;
  justify-content: start !important;
}
@media (min-width: 480px) {
  .paginatedCardsContainer .card-listing__mobile {
    padding: 24px !important;
    justify-content: center;
  }
}
.paginatedCardsContainer :global {
  /* Redesign Start */
}
.paginatedCardsContainer :global .paginated-cards__container .btn-loader {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 1s linear infinite;
  border: 3px solid #d9d9d9;
  border-radius: 50%;
  border-top: 3px solid #336081;
  height: 20px;
  margin-left: 5px;
  position: relative;
  width: 20px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
.paginatedCardsContainer :global .card-listing_item {
  float: none;
  margin-bottom: 38px;
}
.paginatedCardsContainer :global .card-listing__pagination-btn--show-more {
  padding: 0 24px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.paginatedCardsContainer :global .card-listing__show-more--container,
.paginatedCardsContainer :global .card-listing__load-previous--container {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 24px;
}
.paginatedCardsContainer :global .card-listing__viewed {
  margin: 0 0 20px 0;
}
.paginatedCardsContainer :global .v2-button.primary-button.card-listing__pagination-btn {
  margin-bottom: 20px;
}
.paginatedCardsContainer :global .v2-button.primary-button.card-listing__pagination-btn--show-more {
  padding: 0 32px;
}
.paginatedCardsContainer :global .card-listing__v2 {
  border: 1px solid #b7b7b7;
  margin-left: -1px;
  margin-top: -1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  min-width: 0;
}
.paginatedCardsContainer :global .card-listing__v2 > div {
  max-width: 100%;
  width: 100%;
}
.paginatedCardsContainer :global .card-listing__v2.no-padding {
  padding: 0;
}
.paginatedCardsContainer :global .twoColSpanTile {
  grid-column: span 2;
}
.paginatedCardsContainer :global .spotlight-tile {
  grid-area: firstRowLast;
}
@media (min-width: 992px) {
  .paginatedCardsContainer--isPostAuthNav3 :global .card-listing__v2 {
    border-color: #b7b7b7;
  }
}
