@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.modal-close {
  color: #336081;
  font-size: 30px;
  height: 30px;
  width: 30px;
  top: 18px;
  right: 10px;
}
.review-envelopes-modal .addressing-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #e5eef7;
  color: #336081;
  font-size: 16px;
  font-weight: 600;
  padding: 20px;
  margin: 20px;
}
.review-envelopes-modal .header__title .zolaicon {
  margin-right: 0.5em;
}
.review-envelopes-modal .addressing-tiles {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: -10px;
}
@media (max-width: 767px) {
  .review-envelopes-modal .addressing-tiles {
    justify-content: center;
  }
}
@media (min-width: 767px) and (max-width: 991px) {
  .review-envelopes-modal .addressing-tiles {
    justify-content: center;
  }
}
.review-envelopes-modal .addressing-tiles .customization-preview {
  margin-bottom: 20px;
}
.review-envelopes-modal .addressing-tiles .preview-wrapper {
  padding-right: 0;
  margin: 0;
  transform: scale(1);
  margin-bottom: 20px;
}
@media (max-width: 480px) {
  .review-envelopes-modal .addressing-tiles .preview-wrapper {
    transform: scale(0.6);
    margin-bottom: calc(-38%);
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .review-envelopes-modal .addressing-tiles .preview-wrapper {
    transform: scale(0.7);
    margin-bottom: calc(-14%);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .review-envelopes-modal .addressing-tiles .preview-wrapper {
    transform: scale(0.8);
    margin-bottom: calc(-2%);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .review-envelopes-modal .addressing-tiles .preview-wrapper {
    transform: scale(0.9);
    margin-bottom: calc(-2%);
  }
}
.review-envelopes-modal .addressing-tiles .preview-wrapper .card.need-border {
  background: #fff !important;
}
.review-envelopes-modal .addressing-tiles .preview-wrapper .card.need-border,
.review-envelopes-modal .addressing-tiles .preview-wrapper .card {
  overflow: hidden !important;
}
