.recentlyViewedSection {
  background-color: #f9f9f9;
  border-bottom: 1px solid #b7b7b7;
  border-top: 1px solid #b7b7b7;
  margin: 40px 0 30px;
  padding: 60px 40px;
  text-align: center;
}
.container {
  font-size: 20px;
  font-weight: 600;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
@media screen and (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media screen and (max-width: 767px) {
  .container {
    font-size: 24px;
    padding: 60px 20px;
  }
}
.title {
  color: #0e0e0e;
  display: block;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 40px;
}
.title .titleBold {
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-weight: 500;
}
.recentlyViewedCard {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin: 0 10px 6px;
  padding: 10px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.recentlyViewedCard img {
  max-width: 100%;
  max-height: 100%;
}
.carouselArrow {
  border: 0;
  border-radius: 35px;
  color: #0075ae;
  cursor: pointer;
  opacity: 1;
  outline: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s;
  z-index: 200;
}
@media screen and (max-width: 767px) {
  .carouselArrow {
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .carouselArrow {
    display: none;
  }
}
.carouselArrow.carouselArrowLeft {
  left: -40px;
}
.carouselArrow.carouselArrowRight {
  right: -40px;
}
.carouselArrow.carouselArrowDisabled {
  color: #d9d9d9;
}
.noBottomBorder {
  border-bottom: none;
}
