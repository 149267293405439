.container {
  width: auto;
}
.modalBody {
  padding: 20px 30px;
}
.title {
  font-family: 'circular', helvetica, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
}
.text {
  color: #505050;
  font-size: 16px;
  line-height: 24px;
}
.svg {
  height: 100px;
  margin-bottom: 32px;
}
