@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.dropdown-select {
  position: relative;
  text-transform: capitalize;
}
.dropdown-select--is-disabled {
  opacity: 0.4;
}
.dropdown-select--rounded {
  border: 2px solid #d9d9d9;
  border-radius: 4px;
}
.dropdown-select--rounded .form-control {
  font-size: 16px;
}
.dropdown-select:hover {
  cursor: pointer;
}
@media (max-width: 768px) {
  .dropdown-select .form-control {
    height: 50px;
    line-height: 34px;
  }
}
.dropdown-select .form-control.expanded.form-control--options-bottom {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dropdown-select .form-control.expanded.form-control--options-top {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dropdown-select .form-control-match-btn-v2 {
  height: 44px;
  padding: 12px;
  border-radius: 4px;
}
.dropdown-select .zolaicon {
  position: absolute;
  right: 0;
  top: 10px;
  margin-right: 10px;
}
@media (max-width: 768px) {
  .dropdown-select .zolaicon {
    top: 17px;
    color: #0075ae;
  }
}
.dropdown-select .icon-match-btn-v2 {
  color: #0075ae;
  top: 12px;
  text-shadow: 0 1px 0 #0075ae;
}
.dropdown-select .dropdown-options {
  background: white;
  background-color: white;
  display: none;
  width: 100%;
  position: absolute;
  height: 0;
  overflow: hidden;
  z-index: 1;
}
.dropdown-select .dropdown-options.dropdown-options--bottom {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.dropdown-select .dropdown-options.dropdown-options--bottom.dropdown-radius-match-btn-v2 {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.dropdown-select .dropdown-options.dropdown-options--bottom.expanded {
  border-top: none;
}
.dropdown-select .dropdown-options.dropdown-options--top {
  box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.175);
  bottom: 100%;
}
.dropdown-select .dropdown-options.dropdown-options--top.dropdown-radius-match-btn-v2 {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.dropdown-select .dropdown-options.dropdown-options--top.expanded {
  border-bottom: none;
}
.dropdown-select .dropdown-options.expanded {
  border: 1px solid #d9d9d9;
  display: block;
  height: auto;
  max-height: 222px;
  overflow-y: auto;
}
.dropdown-select .dropdown-options .option {
  line-height: 40px;
  background-color: white;
  padding-left: 12px;
}
.dropdown-select .dropdown-options .option:hover {
  background-color: #e2f1f4;
}
.dropdown-select .dropdown-options .option.option-selected {
  font-weight: 600;
}
.dropdown-select .dropdown-options .option.option-disabled {
  background-color: #f7f7f7;
  color: #7f7f7f;
  cursor: default;
}
.dropdown-select .option__tag {
  background: #733658 !important;
  border-color: #733658 !important;
  border-radius: 4px !important;
  color: #fff;
  left: 5px;
  position: relative;
  top: -1px;
}
