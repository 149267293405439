.closeCustomModal {
  align-items: center;
  display: flex;
  flex-direction: column;
  line-height: 1;
  padding: 80px 40px;
}
.title {
  font-size: 32px;
  font-weight: 600;
}
.details {
  margin: 20px 0 40px;
}
.buttons {
  align-items: flex-end;
  display: flex;
  justify-content: center;
}
.buttons button:not(:first-child) {
  margin-left: 12px;
}
