.banner {
  background-size: cover;
  margin-bottom: 34px;
  padding: 28px;
  width: 100%;
}
.banner--is-genericWarning {
  background: #FFFBE8;
  padding: 20px;
}
.banner--is-genericVariation1 {
  background: #f5f7fa;
  padding: 16px 20px;
}
.banner--is-genericVariation2 {
  background: none;
  padding: 0;
}
.banner--is-rounded {
  border-radius: 4px;
}
.banner--has-shadow {
  box-shadow: 0 2px 4px 0 #00000019;
}
.title {
  color: #21201f;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1;
  margin-bottom: 12px;
}
.content {
  color: #505050;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.5;
}
.content--is-genericVariation1 {
  color: #003b58;
  font-weight: 600;
}
:local .content :global(a.zola-ui.link:not(.link-arrow)) {
  text-transform: none;
}
