.shopDesignerContainer {
  padding: 24px;
}
@media (min-width: 768px) {
  .shopDesignerContainer {
    padding: 40px;
  }
}
.shopDesigner {
  display: flex;
  margin: auto;
}
@media screen and (max-width: 991px) {
  .shopDesigner {
    flex-direction: column;
  }
}
.collaboratorWrapper {
  flex: 1 0 50%;
  padding-right: 20px;
}
@media screen and (max-width: 991px) {
  .collaboratorWrapper {
    max-width: 100%;
    padding-right: 0;
    padding-bottom: 40px;
  }
}
.collaboratorContent {
  background-color: #e9eff7;
  border-radius: 4px;
  position: relative;
}
.collaboratorHeader {
  background-image: url('assets/images/shopDesigner/header.svg');
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 104px;
}
.collaboratorPhoto {
  border-radius: 50%;
  height: 120px;
  position: absolute;
  right: 38px;
  top: 44px;
  width: 120px;
}
.collaboratorDetails {
  color: #505050;
  font-size: 16px;
  line-height: 24px;
  padding: 32px;
}
.zolaExclusive {
  color: #21201f;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 20px;
}
.name {
  color: #21201f;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 4px;
}
.location {
  display: flex;
  margin-bottom: 21px;
}
.geoPin {
  margin-right: 4px;
}
.cardsWrapper {
  align-items: center;
  display: flex;
  flex: 1 0 50%;
  margin-right: -10px;
}
@media screen and (max-width: 991px) {
  .cardsWrapper {
    margin-left: -10px;
  }
}
.card {
  font-size: 16px;
  font-weight: 600;
  margin-left: 20px;
  position: relative;
  width: 274px;
}
@media screen and (max-width: 991px) {
  .card {
    margin-left: 0;
    width: 222px;
  }
}
@media screen and (max-width: 767px) {
  .card {
    width: 158px;
  }
}
.cardImg {
  margin: 30px;
  width: 214px;
}
@media screen and (max-width: 991px) {
  .cardImg {
    margin: 0;
    width: 222px;
  }
}
@media screen and (max-width: 767px) {
  .cardImg {
    margin-bottom: 8px;
    width: 158px;
  }
}
.favorite {
  position: absolute;
  right: 12px;
  top: 0;
}
@media screen and (max-width: 991px) {
  .favorite {
    right: 0;
  }
}
