/* stylelint-disable selector-pseudo-class-no-unknown, declaration-no-important */
@media (max-width: 991px) {
  .listingFilterContainer {
    margin-bottom: 16px;
  }
}
.listingFilterContainer div[class*='sortByDropdown'] {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  min-width: 165px;
  width: fit-content;
}
.listingFilterContainer div[class*='sortByDropdown'] label {
  font-weight: 700;
  margin-bottom: 8px !important;
}
@media (max-width: 991px) {
  .listingFilterContainer div[class*='sortByDropdown'] label {
    display: none;
  }
}
.listingFilterContainer div[class*='sortByDropdown'] :global(.input-field__container) {
  min-width: 165px;
}
.stickyFilters {
  height: 0 !important;
}
.stickyFiltersContent {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.filterContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.filterBar {
  align-items: flex-start;
  display: flex;
}
@media (max-width: 767px) {
  .filterBar {
    flex-direction: column;
  }
}
.filterBarWithSampleSelection .filterContainer {
  width: calc(100% - 170px);
}
@media (max-width: 991px) {
  .filterBarWithSampleSelection {
    align-items: center;
  }
  .filterBarWithSampleSelection .filterContainer {
    width: 100%;
  }
}
.listingFilter {
  padding-top: 16px;
  background-color: #ffffff;
}
.topFiltersActive .flex {
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
}
.topFiltersActive .flex div[class*='sortByDropdown'] label {
  display: none;
}
.topFiltersActive .flex .filterContainer {
  max-width: 100%;
  overflow-x: auto;
}
.topFiltersActive .flex .filterContaineWithToggler {
  max-width: calc(100% - 435px);
}
@media (max-width: 991px) {
  .topFiltersActive .flex .filterContaineWithToggler {
    max-width: calc(100% - 260px);
  }
}
@media (max-width: 767px) {
  .topFiltersActive .flex .filterContaineWithToggler {
    max-width: 100%;
  }
}
.multiSampleBox {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  min-width: 260px;
}
.rightPushedContainer {
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  gap: 8px;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  .rightPushedContainer {
    width: 100%;
  }
}
.totalResults {
  line-height: 40px;
}
.filterContainerWithToggle {
  width: calc(100% - 540px);
}
@media (max-width: 991px) {
  .filterContainerWithToggle {
    flex-wrap: nowrap;
    width: calc(100% - 260px);
  }
}
@media (max-width: 767px) {
  .filterContainerWithToggle {
    width: 100%;
  }
}
.plpFilterTagsContainer {
  margin-top: 16px;
}
.multiSampleEnabledRow {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
}
@media (min-width: 992px) {
  .multiSampleEnabledRow {
    padding: 0 40px;
  }
}
@media (min-width: 768px) {
  .multiSampleEnabledRow {
    padding-bottom: 20px;
  }
}
.flex {
  display: flex;
  justify-content: space-between;
  padding: 16px 40px;
  background-color: #ffffff;
}
.flex :global #preview-photo-walkthrough {
  margin-left: auto;
}
