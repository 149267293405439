@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.text-layer-container .element-drag-handle {
  align-items: center;
  background: #55a1bf;
  border-radius: 100%;
  color: #ffffff;
  cursor: move;
  display: flex;
  height: 28px;
  justify-content: center;
  left: calc(50% - 14px);
  position: absolute;
  top: -36px;
  width: 28px;
  z-index: 1000;
}
.text-layer-container .element-resize-handle {
  background-color: #ffffff;
  border: 2px solid #55a1bf;
  border-radius: 100%;
  font-size: 16px;
  height: 10px;
  line-height: normal;
  position: absolute;
  width: 10px;
  z-index: 1000;
}
.text-layer-container .text-layer-placeholder--is-dragging {
  background: rgba(249, 249, 249, 0.3);
  border: 2px solid #55a1bf;
}
.text-layer-container .text-layer.text-layer--error ~ .element-resize-handle,
.text-layer-container .text-layer.text-layer--error ~ .text-layer-placeholder--is-dragging {
  background: rgba(245, 191, 197, 0.3);
  border-color: #c7133e;
}
.text-layer-container .text-layer.text-layer--error ~ .element-drag-handle {
  background: #c7133e;
}
.text-layer-container .text-layer.text-layer--error ~ .element-resize-handle {
  background-color: #f5bfc5;
}
.text-layer-container .text-layer.text-layer--is-dragging:not(.text-layer--is-moveable) {
  background: transparent !important;
  border-color: transparent !important;
}
.text-layer-container .coordinate-line {
  display: flex;
  position: absolute;
  z-index: 10;
}
.text-layer-container .coordinate-line .coordinate-line__value {
  align-items: center;
  background-color: white;
  background-color: rgba(51, 96, 129, 0.55);
  color: white;
  display: flex;
  font-family: 'circular', serif;
  font-size: 12px;
  height: 15px;
  letter-spacing: normal;
  line-height: normal;
  padding-left: 3px;
  padding-right: 3px;
}
.text-layer-container .coordinate-line.coordinate-line--vertical {
  align-items: center;
  border-left: 1px solid #336081;
  width: 1px;
}
.text-layer-container .coordinate-line.coordinate-line--horizontal {
  border-bottom: 1px solid #336081;
  height: 1px;
  justify-content: center;
}
.text-layer-container .coordinate-line.coordinate-line--horizontal .coordinate-line__value {
  bottom: 0;
  position: absolute;
}
.text-layer {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  user-select: none;
  width: 100%;
  z-index: 2;
}
.text-layer--editable:not(.text-layer--is-active):not(.text-layer--error):not(.text-layer--blurred):hover {
  outline: 1px dashed #55a1bf;
}
.text-layer textarea {
  background-color: transparent;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  overflow: visible;
  padding: 0;
  resize: none;
  /* remove the resize handle on the bottom right */
  white-space: pre;
}
.text-layer .text {
  bottom: 0;
  display: inline-block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  white-space: pre;
}
.text-layer .text::after {
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.text-layer .text.text--dragging::after {
  border: none;
}
.text-layer .text.text--editing {
  z-index: -1;
}
.react-draggable-transparent-selection {
  overflow-y: hidden;
}
