.productDetailHeader {
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.13);
  display: flex;
  height: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: height 0.4s ease-in-out;
  width: 100%;
  z-index: 3;
}
.productDetailHeader.productDetailHeaderVisible {
  height: 66px;
}
.productDetailHeader .productDetailStickyHeader {
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 10px;
}
.productDetailHeader .productDetailStickySubHeader {
  color: #505050;
  font-size: 12px;
  font-weight: 200;
  line-height: 1;
}
.productDetailHeader .productDetailStickySubHeaderSeparator {
  color: #d9d9d9;
  margin: 0 3px;
}
.tag {
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .tag {
    margin-left: 14px;
  }
}
.productDetailPromo {
  color: #b20033;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
.pdpActivePriceCustomize {
  background-color: #ffffff;
  padding-top: 10px;
}
.expeditedShipping {
  margin-bottom: 32px;
}
@media screen and (max-width: 991px) {
  .expeditedShipping {
    margin-bottom: 8px;
  }
}
.payLaterWrapperV2 > div:not(empty) {
  margin-top: 32px;
}
@media screen and (max-width: 991px) {
  .payLaterWrapperV2 > div:not(empty) {
    margin-top: 12px;
  }
}
.payLaterWrapper > div:not(:empty) {
  margin-top: 12px;
}
