.productColorPicker {
  align-items: center;
  column-gap: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -16px;
  padding: 0 16px;
  position: relative;
}
@media (min-width: 768px) {
  .productColorPicker {
    column-gap: 4px;
  }
}
.colorWrapper {
  border: 1px solid transparent;
  border-radius: 50%;
  display: inline-block;
  padding: 3px;
  position: relative;
}
.colorWrapper.active {
  border-color: #21201f;
}
.color {
  border: 1px solid #c0c0c0;
  border-radius: 50%;
  cursor: pointer;
  display: block;
}
.color.rainbow {
  background: radial-gradient(50% 50% at 50% 50%, #fff 0%, rgba(255, 255, 255, 0) 100%), conic-gradient(from -90deg at 50% 50%, #c0f -3.06deg, #f00 21.31deg, #f90 56.93deg, #ffe600 105.68deg, #fff500 141.31deg, #3f0 186.31deg, #0ff 236.94deg, #00f7ff 250.06deg, #000aff 293.19deg, #c0f 356.94deg, #f00 381.31deg);
}
.color.rainbow::after,
.color.rainbow::before {
  display: none;
}
@media (min-width: 480px) {
  .color.rainbow::after,
  .color.rainbow::before {
    display: block;
  }
}
.scroll {
  overflow-x: auto;
  flex-wrap: nowrap;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}
.scroll::-webkit-scrollbar {
  /* Safari and Chrome */
  display: none;
}
.moreLink {
  margin-left: 4px;
}
